/* resultList.css */

.result-list-container {
    background-color: #C5DAEE;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .result-list-item {
    margin-bottom: 5px;
  }
  
  .result-list-label {
    font-weight: bold;
  }
  
  .result-list-value {
    margin-right: 10px;
  }