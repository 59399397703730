.table-outer-container {
  overflow-x: auto;
}

.table-container {
  display: inline-block;
  max-width: 100%;
  overflow-x: auto;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .custom-table th,
  .custom-table td {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    text-align: left;
  }
  
  .custom-table th {
    background-color: #205497;
    color: #fff;
    font-weight: bold;
  }
  
  .custom-table tbody tr:hover {
    background-color: #f5f5f5;
  }
  