.featureTable {
    width: 100%;
}

.prettyTable {
    width: 100%;
    border-collapse: collapse;
}

.prettyTable th,
.prettyTable td {
    border: 1px solid #07529A;
    padding: 8px;
    text-align: center;
}

.prettyTable th {
    background-color: #07529A; 
    color: white;
  }

.prettyTable tr:nth-child(even) {
    background-color: #f2f2f2;
}

.prettyTable tr:hover {
    background-color: #ddd;
}


/* Set background color for the second and third columns */
.prettyTable td:nth-child(2),
.prettyTable td:nth-child(3) {
  background-color: #d3eaff; /* Change this to the desired background color */
}

/* Set background color for the fourth and fifth columns */
.prettyTable td:nth-child(4),
.prettyTable td:nth-child(5) {
  background-color: #fceed4; /* Change this to the desired background color */
}